<template>
    <div class="step-content">
        <div class="step-content-header">
            <span class="big">推广类型</span>
            <span class="small">创建计划成功后，计划类型不可更改</span>
        </div>
        <div class="create-box" @click="toNext">
            <div class="create-item">
                <i class="iconfont">&#xe69c;</i>
                <div class="right">
                    创建
                    <i class="iconfont">&#xe613;</i>
                </div>
            </div>
            <div class="create-item line2">店铺推广</div>
            <div class="create-item line3">用于推广您的店铺，以提升您店铺的商品销量。</div>
        </div>
        <el-button class="btn-red-line btn-w-140" @click="cancelBtn">取消</el-button>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
            toNext(){
                if (this.$route.query.editId) {
                    this.$router.push({
                        path: '/student/infographicPromotion/stepTwo',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId: this.$route.query.editId,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/infographicPromotion/stepTwo',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                        }
                    })
                }
            },
            cancelBtn() {
                this.$router.push({
                    path: '/student/infographicPromotion/list',
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .step-content {
        background: #fff;
        padding: 20px 30px;
        height: calc(100vh - 226px);
    }
    .step-content-header {
        font-weight: 500;
        .big {
            font-size: 28px;
        }
        .small {
            color: #959697;
            font-size: 16px;
            margin-left: 20px;
        }
    }
    .create-box {
        margin: 50px 0;
        padding: 40px 60px 80px 40px;
        background: #F5F5F5;
        /*width: 560px;*/
        width: calc(50% - 96px);
        box-sizing: border-box;
        cursor: pointer;
        transition: all .3s;
        .create-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            line-height: 1;
            font-size: 16px;
            & > .iconfont {
                color: #DFDFDF;
                font-size: 40px;
                transition: all .3s;
            }
            .right {
                font-size: 36px;
                display: flex;
                align-items: center;
                .iconfont {
                    font-size: 30px;
                }
            }
            &.line2 {
                font-size: 24px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &:hover {
            color: #FD4446;
            .create-item {
                & > .iconfont {
                    color: #FD4446;
                }
            }
        }
    }
</style>